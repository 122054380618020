import React from "react"
import "../../sass/app.scss"

const Designer = ({ header, children }) => (
  <>
    <main id="designer-wrapper">
      <section className="section designer-header">
        <p className="designer-header--step">{header.step}</p>

        <div className="designer-header--content">
          <h1 className="is-size-4">{header.title}</h1>
          <h2 className="subtitle">{header.subtitle}</h2>
        </div>
      </section>
      <section className="section">{children}</section>
    </main>
  </>
)

export { Designer }

import React, { useContext } from "react"
import { navigate } from "gatsby"
import { StoreContext } from "../../context/StoreContext"

const AddToCart = ({
  props,
  variantId,
  price,
  custom,
  isValid,
  text,
  locationState,
}) => {
  const {
    addProductToCart,
    updateLineItem,
    toggleCartOpen,
    checkout,
  } = useContext(StoreContext)

  let customAttributes = Object.entries(custom)
  customAttributes = customAttributes.reduce((arr, curr) => {
    return [...arr, { key: curr[0], value: curr[1] }]
  }, [])

  //console.log("fff", edit, lineItemId)
  return (
    <a
      type="button"
      className="button is-primary is-fullwidth has-text-weight-bold is-size-5"
      style={{
        borderRadius: 0,
        width: "100%",
      }}
      onClick={() => {
        if (isValid()) {
          if (locationState) {
            const { edit, lineItemId, showUpgrade } = locationState
            if (edit) {
              updateLineItem(lineItemId, variantId, customAttributes)
              navigate("/cart")
            } else {
              addProductToCart({
                variantId,
                price,
                customAttributes,
                ref: props.location.pathname,
              })

              if (showUpgrade) {
                navigate("/upgrade", {
                  state: {
                    designer: custom._handle
                  },
                })
              } else {
                navigate('/cart')
              }
            }
          } else {
            addProductToCart({
              variantId,
              price,
              customAttributes,
              ref: props.location.pathname,
            })
            navigate("/upgrade")
          }

          //toggleCartOpen()
          //window.location = checkout.webUrl
        }
      }}
    >
      {text}
    </a>
  )
}

export default AddToCart

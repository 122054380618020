import React from "react"
import Img from "gatsby-image"

const SelectBackground = ({ backgrounds, selectImage }) => {
  const getBackgrounds = () => {
    return backgrounds.map(background => (
      <div key={background.img.id} className="column is-8 is-offset-2">
        <div className="box">
          <span onClick={() => selectImage(background)}>
            <div className="card">
              <header className="card-header">
                <p className="card-header-title is-centered">
                  {background.title}
                </p>
              </header>
              <div className="card-image">
                <figure className="image">
                  <Img
                    fluid={background.img.childImageSharp.fluid}
                    alt={`Product Image`}
                  />
                </figure>
              </div>
              <footer className="card-footer">
                <div className="card-footer-item">
                  <button className="button is-primary is-rounded is-fullwidth has-text-weight-bold">
                    SELECT
                  </button>
                </div>
              </footer>
            </div>
          </span>
        </div>
      </div>
    ))
  }
  return <div className="columns is-multiline">{getBackgrounds()}</div>
}

export { SelectBackground }

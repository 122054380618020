import React, { Component, useContext } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { FaUpload } from "react-icons/fa"
import { Storage } from "aws-amplify"
import uuidv1 from "uuid/v1"
import { FaChevronLeft } from "react-icons/fa"
import SEO from "../components/seo"
import "../sass/app.scss"
import {
  Designer,
  SelectBackground,
  BuildProductOptions,
} from "../components/Designer"
import AddToCart from "../components/Cart/AddToCart"

class DesignerTemplate extends Component {
  state = {
    header: {
      step: 1,
      title: "Choose a Background",
      subtitle: "Which background would you like your fur-baby drawn on?",
    },
    modal: {
      show: false,
      title: "",
      content: "",
    },
    showStep1: true,
    showStep2: false,
    selectedBackground: null,
    petName: "",
    petpic: null,
    uploading: false,
    productOptions: {},
    email: "",
    addInfo: "",
    nextButton: "FETCH NOW!",
    selectedVariantId: null,
    selectedVariantPrice: null,
    percent: 0,
    skipImage: false,
  }

  componentDidMount() {
    const {
      product: { options },
      designer,
    } = this.props.data

    console.log("ddd", this.props)
    const { state } = this.props.location
    console.log("ddd", state)

    if (state) {
      const { edit, customAttributes, selectedOptions } = state

      if (edit) {
        const backgroundName = customAttributes.find(
          atr => atr.key === "_Background"
        ).value
        const editBackground = designer.frontmatter.designer.backgrounds.find(
          item => item.title === backgroundName
        )

        const productOptions = {}

        selectedOptions.map(option => {
          productOptions[option.name] = option.value
        })

        console.log("fff", productOptions)

        this.setState(
          {
            showStep1: false,
            showStep2: true,
            selectedBackground: editBackground,
            petName: customAttributes.find(atr => atr.key === "Name").value,
            email: customAttributes.find(atr => atr.key === "_Email").value,
            addInfo: customAttributes.find(atr => atr.key === "Info").value,
            petpic:
              customAttributes.find(atr => atr.key === "_Pic").value ===
                "skipImage"
                ? null
                : customAttributes.find(atr => atr.key === "_Pic").value,
            skipImage:
              customAttributes.find(atr => atr.key === "_Pic").value ===
              "skipImage",
            productOptions,
            header: {
              step: 2,
              title: "About Your Pup",
              subtitle:
                "Final step! Tell us about your pet and product details.",
            },
          },
          () => this.findVariant(productOptions)
        )
      } else {
        const productOptions = {}

        options.map(option => (productOptions[option.name] = option.values[0]))

        this.setState(
          {
            productOptions,
          },
          () => this.findVariant(productOptions)
        )
      }
    } else {
      const productOptions = {}

      options.map(option => (productOptions[option.name] = option.values[0]))

      this.setState(
        {
          productOptions,
        },
        () => this.findVariant(productOptions)
      )
    }
  }

  isValid() {
    const { petName, petpic, email } = this.state

    if (!petName) {
      this.setState({
        modal: {
          ...this.state.modal,
          showModal: true,
          modalTitle: "Error",
          modalContent: "Please enter a pet name.",
        },
      })

      return false
    } else if (!petpic && !this.state.skipImage) {
      this.setState({
        modal: {
          ...this.state.modal,
          showModal: true,
          modalTitle: "Error",
          modalContent: "Please upload a pet image.",
        },
      })
      return false
    } else if (!email) {
      this.setState({
        modal: {
          ...this.state.modal,
          showModal: true,
          modalTitle: "Error",
          modalContent: "Please enter an email address.",
        },
      })
      return false
    }

    return true
  }

  selectImage(background) {
    this.setState(
      {
        selectedBackground: background,
        showStep1: false,
        showStep2: true,
        header: {
          step: 2,
          title: "About Your Pup",
          subtitle: "Final step! Tell us about your pet and product details.",
        },
      },
      () => this.scrollTop()
    )
  }

  selectProductOption(option, e) {
    const productOptions = {
      ...this.state.productOptions,
      [option.name]: e.target.value,
    }

    const customOptions = document.getElementsByName("custom_options")

    customOptions.forEach(option => {
      productOptions[option.id] = option.value
    })

    this.setState({
      productOptions: {
        ...this.state.productOptions,
        ...productOptions,
      },
    })

    this.findVariant(productOptions)
  }

  findVariant(productOptions) {
    const {
      product: { variants },
    } = this.props.data

    console.log("ggg", productOptions)

    variants.map(({ shopifyId, selectedOptions, price }) => {
      const isMatch = selectedOptions.map(({ name, value }) => {
        if (productOptions[name] === value) {
          return true
        } else {
          return false
        }
      })

      if (!isMatch.includes(false)) {
        console.log("ggg", shopifyId)
        this.setState({
          selectedVariantId: shopifyId,
          selectedVariantPrice: price,
        })
      }
    })
  }

  uploadFile(e) {
    console.log(e.target.files)

    this.setState({ uploading: true })

    const file = e.target.files[0]

    const customPrefix = {
      public: "public/petpics/",
    }

    let temp = this

    Storage.put(`${uuidv1()}.${file.name.split(".").pop()}`, file, {
      contentType: file.type,
      customPrefix: customPrefix,
      metadata: { handle: this.props.pageContext.handle },
      progressCallback(progress) {
        const percent = ((progress.loaded / progress.total) * 100).toFixed(2)
        console.log(`Uploaded: ${percent}%`)

        temp.setState({ percent })
      },
    })
      .then(result =>
        Storage.get(`petpics/${result.key}`).then(a =>
          this.setState({ petpic: a.split("?")[0], uploading: false })
        )
      )
      .catch(err => console.log(err))
  }

  getUploadField() {
    if (this.state.petpic && !this.state.skipImage) {
      return (
        <div className="field">
          <div className="card" style={{ width: 100, height: "auto" }}>
            <div className="card-image">
              <figure>
                <img src={this.state.petpic} width={"auto"} height={"100%"} />
              </figure>
            </div>
            <footer className="card-footer">
              <button
                className="button btn-default is-fullwidth"
                onClick={() => this.setState({ petpic: null })}
              >
                Replace
              </button>
            </footer>
          </div>
          <label className="checkbox" style={{ marginTop: 10 }}>
            <input
              type="checkbox"
              checked={this.state.skipImage}
              onChange={() =>
                this.setState({
                  skipImage: !this.state.skipImage,
                  petpic: null,
                })
              }
            />
            <span>
              {" "}
              I prefer to email my pet image to{" "}
              <a href="mailto:info@waggtopia.com">info@waggtopia.com</a> after
              placing my order.
            </span>
          </label>
        </div>
      )
    }

    return (
      <div className="field">
        {!this.state.skipImage && (
          <>
            <label className="label">Pet Image</label>
            <div className="file">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  name="resume"
                  onChange={e => this.uploadFile(e)}
                  disabled={this.state.uploading}
                />
                <span className="file-cta">
                  {this.state.uploading ? (
                    <div className="loader"></div>
                  ) : (
                      <FaUpload style={{ marginRight: 10 }} />
                    )}
                  <span className="file-label">
                    {this.state.uploading
                      ? `Uploading... (${this.state.percent}%)`
                      : "Choose a file..."}
                  </span>
                </span>
              </label>
            </div>
          </>
        )}
        <label className="checkbox" style={{ marginTop: 10 }}>
          <input
            type="checkbox"
            checked={this.state.skipImage}
            onChange={() => this.setState({ skipImage: !this.state.skipImage })}
          />
          <span>
            {" "}
            I prefer to email my pet image to{" "}
            <a href="mailto:info@waggtopia.com">info@waggtopia.com</a> after
            placing my order.
          </span>
        </label>
      </div>
    )
  }

  goBack() {
    const { showStep1, showStep2 } = this.state

    if (showStep1) {
      window.history.back()
    } else if (showStep2) {
      this.setState(
        {
          showStep1: true,
          showStep2: false,
          header: {
            step: 1,
            title: "Choose a Background",
            subtitle: "Which background would you like your fur-baby drawn on?",
          },
        },
        () => this.scrollTop()
      )
    }
  }

  scrollTop() {
    document.getElementById("designer-wrapper").scrollTop = 0
    document.body.scrollTop = 0
  }

  render() {
    console.log("fff", this.props.location.state)

    const { designer, product, christmas_guarantee } = this.props.data

    return (
      <>
        <SEO title={`Designer - ${product.title}`} />
        <div className="columns" style={{ height: "100vh", width: "auto" }}>
          <div
            className="column is-7"
            id="designer-wrapper"
            style={{
              overflowY: "scroll",
            }}
          >
            <Designer header={this.state.header}>
              <div className="container">
                {this.state.showStep1 && designer && (
                  <SelectBackground
                    backgrounds={designer.frontmatter.designer.backgrounds}
                    selectImage={this.selectImage.bind(this)}
                  />
                )}
                {this.state.showStep2 && (
                  <div className="columns is-multiline">
                    <div className="column is-12 is-hidden-desktop">
                      <div className="box">
                        <h3
                          className="has-text-centered has-text-black-ter is-uppercase has-text-weight-bold"
                          style={{ marginBottom: 10 }}
                        >
                          {product.title}
                        </h3>

                        <figure>
                          <Img
                            fluid={
                              this.state.selectedBackground !== null
                                ? this.state.selectedBackground.img
                                  .childImageSharp.fluid
                                : product.images[0].localFile.childImageSharp
                                  .fluid
                            }
                          />
                        </figure>
                      </div>
                    </div>

                    <div className="column is-12">
                      <div className="box field">
                        <div className="columns is-multiline">
                          <div className="column is-12">
                            <div className="field">
                              <label className="label">Pet Name</label>
                              <div className="control">
                                <input
                                  className={`input ${this.state.petName
                                    .length > 0 && "is-success"}`}
                                  type="text"
                                  placeholder="Ruby"
                                  value={this.state.petName}
                                  onChange={e =>
                                    this.setState({ petName: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="column is-12">
                            {this.getUploadField()}
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      <BuildProductOptions
                        product={product}
                        selectProductOption={this.selectProductOption.bind(
                          this
                        )}
                        selectedProductOption={() => this.state.productOptions}
                      />
                    }

                    <div className="column is-12">
                      <div className="box field">
                        <label className="label">Email Address</label>
                        <small>
                          Please check your email regularly as we may need to
                          contact you about your order.
                        </small>
                        <div className="control" style={{ marginTop: 10 }}>
                          <input
                            className={"input"}
                            type="email"
                            value={this.state.email}
                            onChange={e =>
                              this.setState({ email: e.target.value })
                            }
                            placeholder="doggie@gmail.com"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="column is-12">
                      <div className="box field">
                        <label className="label">Additional Info</label>
                        <div className="control">
                          <textarea
                            className="textarea"
                            placeholder=""
                            value={this.state.addInfo}
                            onChange={e =>
                              this.setState({ addInfo: e.target.value })
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    {product.descriptionHtml && (
                      <div className="column is-12">
                        <div className="box field">
                          <div className="control">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: product.descriptionHtml,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="column is-12">
                      <div className="box field">
                        <div className="control">
                          <div
                            className="columns is-vcentered has-text-centered is-multiline"
                          >
                            <div className="column is-12">
                              <Img
                                className=""
                                fixed={christmas_guarantee.childImageSharp.fixed}
                              />
                            </div>
                            <div className="column is-12">
                              <p
                                className="has-text-centered is-size-4 has-text-weight-bold"
                                style={{ color: "#02b0f1" }}
                              >
                                GUARANTEED TO ARRIVE BEFORE CHRISTMAS OR YOUR MONEY
                                BACK!
                      </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
            </Designer>
          </div>
          <div
            className="column is-5 is-hidden-mobile"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="box"
              style={{
                flex: 1,
                marginRight: 10,
              }}
            >
              <h3
                className="has-text-centered is-size-4 is-uppercase has-text-weight-bold has-text-black-ter"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                {product.title}
              </h3>
              <figure className="image">
                <Img
                  fluid={
                    this.state.selectedBackground !== null
                      ? this.state.selectedBackground.img.childImageSharp.fluid
                      : product.images[0].localFile.childImageSharp.fluid
                  }
                />
              </figure>
            </div>
          </div>
        </div>

        <footer id="designer-footer">
          <div
            className="columns is-gapless is-multiline is-centered is-vcentered is-mobile"
            style={{ marginBottom: 0 }}
          >
            <div className="column is-1">
              <button
                className="button"
                style={{ height: 65 }}
                onClick={() => this.goBack()}
              >
                <FaChevronLeft />
              </button>
            </div>
            <div className="column is-11 has-text-centered">
              <strong
                style={{ color: "black", paddingLeft: 10, paddingRight: 10 }}
              >
                {product.title} {"- "}
                <strong
                  className="has-text-weight-bold"
                  style={{ color: "rgb(2, 176, 241)" }}
                >
                  ${this.state.selectedVariantPrice}
                </strong>{" "}
                <strike className="has-text-grey is-size-7">
                  ${parseFloat(this.state.selectedVariantPrice) * 2}
                </strike>
              </strong>
              <p style={{ color: "green" }}>
                You save $
                {parseFloat(this.state.selectedVariantPrice) * 2 -
                  parseFloat(this.state.selectedVariantPrice)}{" "}
                (50%)
              </p>
            </div>
          </div>
          {/*  */}

          {this.state.selectedBackground && (
            // <div className="column">
            <AddToCart
              props={this.props}
              text={"PERSONALIZE"}
              variantId={this.state.selectedVariantId}
              price={this.state.selectedVariantPrice}
              custom={{
                _Background: this.state.selectedBackground.title,
                Name: this.state.petName,
                _Pic: this.state.skipImage ? "skipImage" : this.state.petpic,
                _Email: this.state.email,
                Info: this.state.addInfo,
                _handle: this.props.pageContext.handle,
              }}
              isValid={this.isValid.bind(this)}
              locationState={this.props.location.state}
            />
            // </div>
          )}
        </footer>

        <div
          className={`modal ${this.state.modal.showModal && "is-active"}`}
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        >
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{this.state.modal.modalTitle}</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() =>
                  this.setState({
                    modal: {
                      ...this.state.modal,
                      showModal: false,
                    },
                  })
                }
              ></button>
            </header>
            <section className="modal-card-body">
              {this.state.modal.modalContent}
            </section>
            <footer className="modal-card-foot">
              <button
                className="button"
                onClick={() =>
                  this.setState({
                    modal: {
                      ...this.state.modal,
                      showModal: false,
                    },
                  })
                }
              >
                Close
              </button>
            </footer>
          </div>
        </div>
      </>
    )
  }
}

export const query = graphql`
  query($shopifyHandle: String) {
    product: shopifyProduct(handle: { eq: $shopifyHandle }) {
      title
      descriptionHtml
      variants {
        id
        availableForSale
        price
        selectedOptions {
          name
          value
        }
        shopifyId
      }
      options {
        id
        name
        values
      }
      images {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    designer: markdownRemark(
      frontmatter: { shopifyHandle: { eq: $shopifyHandle } }
    ) {
      frontmatter {
        designer {
          backgrounds {
            title
            img {
              id
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    christmas_guarantee: file(relativePath: { eq: "christmas_guarantee_2.png" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default DesignerTemplate

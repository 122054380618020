import React from "react"

const BuildProductOptions = ({
  product,
  selectProductOption,
  selectedProductOption,
}) => {
  console.log(product)
  return product.options.map(option => (
    <div key={option.id} className="column is-12">
      <div className="step box field">
        <label className="label">{option.name}</label>
        <div className="control">
          <div className="select is-rounded">
            <select
              name="custom_options"
              id={option.name}
              onChange={e => selectProductOption(option, e)}
              value={selectedProductOption()[option.name]}
            >
              {option.values.map(
                value =>
                  value !== "N/A" && (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  )
              )}
            </select>
          </div>
        </div>
      </div>
    </div>
  ))
}

export { BuildProductOptions }
